import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_MAGNIFYING_GLASS,
  Button,
  ButtonWrapper,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { useLocation } from 'react-router-dom';
import UnsupportedNonMobileError from "../dashboard/PresenceVerification/UnsupportedNonMobileError";

const renderContent = (state) => {
  const { t } = useTranslation();
  if(state?.applicationType){
    return (
      <UnsupportedNonMobileError isMobileDevice={state?.isMobile} browserSupported={state?.supported} />
    );
  } else {
    return (
      <>
        <h2 className="h3 max-width--800 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
          {t("screens-MDL-error-heading", "Oops, something went wrong")}
        </h2>
        <p className="text--sm mb-24 text--center max-width--600">
          {t("screens-MDL-error-message", "We cannot process your request right now.")}
        </p>
      </>
    )
  }
}

function DynamicErrorScreen({ title }) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const formProps = { isForm: false };
  const imageLink = process.env.REACT_APP_IMAGE_PATHS + "/img/icons/Hand_Holding_cell_phone.png";
  
  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center">
        <div className="mb-40 mt-40">
          <>
          {(state?.applicationType && !state?.isMobile) ?
            <img 
              src={imageLink}
              alt="Hand holding cell phone."
              width="110"
            /> :
            <Icon
              icon={ICON_MAGNIFYING_GLASS}
              isDecorative
              className="w--100 max-width--300 bp-md:max-width--400"
            />}
          </>
        </div>
       {renderContent(state)}

      </div>
    </RadvPageWrapper>
  );
}

export default DynamicErrorScreen;
