const InitializeBBY = (callback) => {
  if (document.getElementById("web-capture-script")) {
    return;
  }
  const script = document.createElement("script");
  script.src = "/libs/abbyy-mwc/js/web-capture.js";
  script.id = "web-capture-script"
  script.async = true;
  const styles = document.createElement("link");
  styles.rel = "stylesheet";
  styles.href = "/libs/abbyy-mwc/css/web-capture.css";

  // Set callback for MWC initialization
  script.onload = () => aBBYYOnLoad(callback);
  // Add MWC script to DOM
  document.body.appendChild(script);
  document.head.appendChild(styles);


  async function aBBYYOnLoad(callback) {
    try {
      await window.ABBYY.WebCapture.init({
        // licenseFilePath: process.env.REACT_APP_MWC_LICENSE_URL,
        licenseFilePath: `//${window.location.host}/libs/abbyy-mwc/MWC.ABBYY.License`,
        wasmFilesPath: `//${window.location.host}/libs/abbyy-mwc/js`,
        viewConfiguration: {
          "captureScreen": {
            "galleryButton": "visible",
          },
        }
      });
    } catch (error) {
        callback(error)
    }
  }
}

export default InitializeBBY;
