import { useTranslation } from '@ca-dmv-radv/translation';
import React from 'react';

export default function UnsupportedNonMobileError({ isMobileDevice, browserSupported }) {
  const { t } = useTranslation();

  let headingKey, headingValue, messageKey, messageValue;

  if (!isMobileDevice) {
    headingKey = "screens-error-mobile-device-heading";
    headingValue = "Please use a mobile device to proceed";
    messageKey = "screens-error-mobile-device-message";
    messageValue = "The link provided to upload your documents must be accessed from your mobile device. Please try again."
  } else {
      if (browserSupported === false) {
        headingKey = "screens-iproov-unsupported-heading";
        headingValue = "Please use a different browser to proceed";
        messageKey = "screens-iproov-unsupported-message";
        messageValue = "The following browser is unsupported. Please try again with another browser.";
      }
  }

  return (
    <>
      <h2 className="h3 max-width--800 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
        {t(headingKey, headingValue)}
      </h2>
      <p className="text--sm mb-24 text--center max-width--600">
        {t(messageKey, messageValue)}
      </p>
    </>
  );
}