import React, { useCallback, useEffect, useState } from "react";
import {
  YesNoRadioGroup,
  Button,
  ButtonWrapper,
  useFocusedKey,
} from "@ca-dmv/core";
import { useTranslation, Trans } from "@ca-dmv-radv/translation";
import {
  RadvPageWrapper,
  NameConfirmationCard,
  CARD_RID_APPLICATION_NAME,
  ButtonSaveAndExit,
  ButtonBack,
} from "@ca-dmv-radv/components";
import { useNameChanges, useIdentityDocument, useApplication } from "@ca-dmv-radv/data";
import { formatName, makeId } from "@ca-dmv-radv/utilities";
import useNameChangeErrors from "./useNameChangeErrors";
import FormFields from "./FormFields";
import NameCards from "./NameCards";
import SelectDocument from "./SelectDocument";
import useFocusableElement from "./useFocusableElements";
import { useLocation, useNavigate } from "react-router-dom";
import useNameChangeNavigation from "./useNameChangeNavigation";
import useGenericConfirmNameNavigation from "../dashboard/ProofOfDocument/useGenericConfirmNameNavigation";

export default function GenericNameChanges({ title }) {
  const {
    nameChanges,
    nameOnDocument,
    removeNameChange,
    addNameChangeDocument: saveAddedNameChange,
    nameChangeDocuments,
    setCatDocId: globalSetCatDocId,
    hasNameChangeDocs,
  } = useNameChanges();
  const { application, applicationType } = useApplication();
  const { showIdentityNameOptions } = useIdentityDocument();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const proofOfDocumentCategory = location.state?.proofOfDocumentCategory;
  const selectedDocument = location.state?.selectedDocument;

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [catDocId, setCatDocId] = useState(null);
  const [needAnotherNameChange, setNeedAnotherNameChange] = useState(
    nameChanges[nameChanges.length - 1]?.catDocId === null ? true : null
  );
  const [showErrors, setShowErrors] = useState(false);

  const { focusedElementKey } = useFocusedKey();
  const errors = useNameChangeErrors({
    needAnotherNameChange,
    firstName,
    lastName,
    catDocId,
    nameChanges,
  });
  const hasErrors = Boolean(Object.keys(errors).length);
  const nameChangeRef = useFocusableElement();

  /**
   * Handle click on "Add New Name" button.
   */
  const requestAddNameChange = useCallback(async () => {
    setShowErrors(hasErrors);

    if (hasErrors) {
      return;
    }

    saveAddedNameChange({
      firstName,
      middleName,
      lastName,
      suffix,
      done: false,
      id: null,
      key: makeId(),
      catDocId,
      // When the user lands on this screen for the first time, the first thing we need to do
      // is get the catDocId for the document certifying the name on their application. This
      // name change is always the last item in the nameChanges array, so if catDocId is null,
      // we know we need to set that catDocId rather than adding a whole new name change.
      isApplicationName:
        nameChanges.length === 2 &&
        nameChanges[nameChanges.length - 1]?.catDocId === null,
    });

    setNeedAnotherNameChange(null);
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setSuffix("");
    setCatDocId(null);

    window.scrollTo(0, 0);
  }, [
    hasErrors,
    nameChanges,
    saveAddedNameChange,
    firstName,
    middleName,
    lastName,
    suffix,
    catDocId,
  ]);

  const { navigating, requestNavigation } = useNameChangeNavigation({
    setShowErrors,
    hasErrors,
  });

  const nameOnFile = () => {
    if (application?.application?.nameOnFile) {
      return formatName(application.application.nameOnFile);
    } else {
      return "";
    }
  }

  // Reset needAnotherNameChange to true when the last nameChange's catDocId becomes null.
  useEffect(() => {
    if (nameChanges[nameChanges.length - 1]?.catDocId === null) {
      setNeedAnotherNameChange(true);
    }
  }, [nameChanges]);

  useEffect(() => {
    if (hasNameChangeDocs === null) {
      const navigateStateObj = {
        proofOfDocumentCategory: proofOfDocumentCategory,
        selectedDocument: selectedDocument,
      };
      navigate("/name-confirmation", {
        state: navigateStateObj
      });
    }
  }, [hasNameChangeDocs]);

  return (
    <RadvPageWrapper
      pageHeading={t(
        `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-legend`, 
        `Proof of ${proofOfDocumentCategory.categoryName}`
      )}
      formProps={{
        isForm: true,
        showErrors,
        errors,
      }}
      pageTitle={title}
    >
    {!showIdentityNameOptions && 
        <div>
          <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
            {t(
              "screens-returnToDashboard-backButton-warning",
              "Please avoid using the browser navigation buttons to make changes to your application."
            )}
          </p>
          <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t("screens-returnToDashboard-button-text", "Return to Dashboard")}
            buttonClass="mb-24 bp-md:mb-0"
            onClick={requestNavigation}
            isLoading={navigating}
          />
        </ButtonWrapper>
        </div>
    }
    {showIdentityNameOptions && <div>
      <h3 className="text--h5 text--700 mb-8 bp-md:mb-16">
        {t("screens-name-change-certification-subHeading", "Name Change Certification")}
      </h3>
      <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
        {t(
          "nch-screens-confirm-name-iisthisYourNameSubLegend",
          "If the name on your application does not match the name on your document, you will need to provide certification of the different name. If you have changed your name multiple times, you may be asked to provide certification for each name change."
        )}
      </p>
      <div className="flex flex--col bp-md:flex--row mb-40 bp-md:mb-48 w--100 max-width--600 mr-0">
        <NameCards
          nameChanges={nameChanges}
          removeNameChange={removeNameChange}
          certifyingDocuments={nameChangeDocuments}
          setCatDocId={globalSetCatDocId}
          selectedIdentityDocument={selectedDocument}
        />
      </div>
      <div ref={nameChangeRef} className="max-width--600">
        {!nameChanges[nameChanges.length - 1]?.catDocId && (
          <SelectDocument
            legend={
              <Trans
                i18nKey="nch-screens-confirm-name-applicationNameCertifyingApplication"
                defaults="Select the document that certifies the name change between <span>{{nameOnFile}} (name on file)</span> to <span>{{fullName}}</span>"
                values={{
                  nameOnFile: nameOnFile(),
                  fullName: formatName(nameChanges[0])
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            documentList={nameChangeDocuments}
            onChange={(nextValue) => {
              setCatDocId(Number(nextValue));
            }}
            error={errors && showErrors && errors.certifyingDoc}
            documentId={catDocId}
            name="certifyingDoc"
            selectLabel={t(
              "screens-confirm-namme-selectDocument",
              "Select one of the documents below"
            )}
            required
            idField="id"
          />
        )}

        {nameChanges[nameChanges.length - 1]?.catDocId && (
          <>
            <YesNoRadioGroup
              containerClass="mb-0 w--100 max-width--600"
              name="addAnotherNameChange"
              legend={
                <Trans
                  i18nKey="nch-screens-confirm-name-doYouNeedAnotherNameChangeLegend"
                  defaults="Have you had any other changes to your name between <span>{{nameOnFile}}</span> and <span>{{fullName}}</span>?"
                  values={{
                    fullName: nameOnDocument,
                    nameOnFile: nameOnFile()
                  }}
                  components={{ span: <span className="text--blue" /> }}
                />
              }
              subLegend={t(
                "screens-confirm-name-doYouNeedAnotherNameChangeDescription",
                "You need to provide a document to certify every time your name has changed."
              )}
              yesLabel={t("shared.yesLabel", "Yes")}
              noLabel={t("shared.noLabel", "No")}
              onChange={setNeedAnotherNameChange}
              value={needAnotherNameChange}
              focusedElementKey={focusedElementKey}
              showErrors={showErrors}
              errorKey="needAnotherNameChange"
              translatedErrors={errors}
              yesValue
              noValue={false}
            />
            {needAnotherNameChange && (
              <FormFields
                firstName={firstName}
                setFirstName={setFirstName}
                middleName={middleName}
                setMiddleName={setMiddleName}
                lastName={lastName}
                setLastName={setLastName}
                suffix={suffix}
                setSuffix={setSuffix}
                catDocId={catDocId}
                setCatDocId={setCatDocId}
                nameChangeDocuments={nameChangeDocuments}
                focusedElementKey={focusedElementKey}
                showErrors={showErrors}
                errors={errors}
              />
            )}
          </>
        )}
      </div>
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <ButtonBack buttonClass="bp-md:mr-24" />
        <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        {needAnotherNameChange ? (
          <Button
            label={t(
              "screens-confirm-name-addNameChangeDocument",
              "Add Certifying Document"
            )}
            buttonClass="mb-24 bp-md:mb-0"
            buttonPurpose={t(
              "screens-confirm-name-addNameChangeDocument",
              "Add Certifying Document"
            )}
            onClick={requestAddNameChange}
          />
        ) : (
          <Button
            label={t("shared.nextLabel", "Next")}
            buttonClass="mb-24 bp-md:mb-0"
            onClick={requestNavigation}
            isLoading={navigating}
          />
        )}
      </ButtonWrapper>
      </div>}
    </RadvPageWrapper>
  );
}