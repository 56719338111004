import { SMS_SENDING, useSMS } from '@ca-dmv-radv/data';
import { useTranslation } from '@ca-dmv-radv/translation';
import { Button, BUTTON_STYLE_LINK, Note } from '@ca-dmv/core';
import { Modal } from '@ca-dmv/modal';
import React from 'react';
import { isSignature } from '../helpers/constants';

export default function TakePhotoWaiting({ onClose, document }) {
  const { t } = useTranslation();
  const { phoneNumber, sendingSMS, resetModal, tokenExpiration } = useSMS();

  const handleOnClose = () => {
    resetModal();
    onClose();
  };

  return (
    <Modal
      onClose={handleOnClose}
      modalTitle={t(
        "screens-Dashboard-Modal-TakePhotoWaiting-modalTitle",
        "Waiting for your photos..."
      )}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      <p className="text--xmd text--lh-sm text--blue-dark-2">
        {t(
          "screens-Dashboard-Modal-TakePhotoWaiting-modalsubtitle1",
          "A text message has been sent to {{formattedPhoneNumber}}.",
          { formattedPhoneNumber: phoneNumber }
        )}
      </p>
      <p className="text--xmd text--lh-sm text--blue-dark-2">
        {isSignature(document)
          ? t(
              "screens-Dashboard-Modal-TakePhotoWaiting-signature-modalsubtitle2",
              "Tap the link and follow the instructions to take a photo of your signature."
            )
          : t(
              "screens-Dashboard-Modal-TakePhotoWaiting-modalsubtitle2",
              "Please click the link and follow the instructions to take a photo of your document."
            )}
      </p>
      {!isSignature(document) && tokenExpiration && tokenExpiration !== 0 && (
        <p className="text--xmd text--lh-sm text--blue-dark-2 mb-40">
          {t(
            "screens-Dashboard-Modal-TakePhotoWaiting-modalsubtitle3",
            "The link will expire in {{tokenExpiration}} minutes.",
            { tokenExpiration: tokenExpiration }
          )}
        </p>
      )}

      <Note note="" />
      <p className="text--blue-dark-2 mt-10">
        {isSignature(document)
          ? t(
              "nch-screens-Dashboard-Modal-TakePhotoWaiting-note",
              "The document status will automatically update on the document submission page once your photos are received."
            )
          : t(
              "screens-Dashboard-Modal-TakePhotoWaiting-note",
              "The document upload page will automatically update the document status once your photos are received."
            )}
      </p>

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          label={
            sendingSMS !== SMS_SENDING
              ? t(
                  "screens-Dashboard-Modal-TakePhotoWaiting-buttonSendAgainLabel",
                  "Send it again"
                )
              : t("shared.resending", "Resending ...")
          }
          disabled={sendingSMS === SMS_SENDING}
          btnStyle={BUTTON_STYLE_LINK}
          onClick={() => {
            resetModal();
          }}
        />
        <Button
          label={t(
            "screens-Dashboard-Modal-TakePhotoWaiting-buttonOkLabel",
            "Ok"
          )}
          onClick={handleOnClose}
        />
      </div>
    </Modal>
  );
}
