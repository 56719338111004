export const status = {
  EXF: "EXF",
  CNT: "CNT",
  PUC: "PUC",
};

export const aidEnabledCatDocIds = [1, 2, 60, 61, 62, 82, 83, 120, 121];
export const multipPageCatDocIds = [2, 60, 8, 62, 83, 121];

export const isSignature = (document) => 
  document.shortName === "Signature";

export const isBirthCertificate = (document) =>
  document.shortName === "U.S. Birth Certificate";

export const isPassPort = (document) =>
  document.shortName === "U.S. Passport";

export const isPassportCard = (document) =>
  document.shortName === "U.S. Passport Card";

export const isDriversLicense = (document) =>
  document.shortName === "Driving License" || document.shortName === "DL";

export const isIdentificationCard = (document) => 
  document.shortName === "ID";

export const isAbbyyFlow = (ATD) => ["DNQ", "EXF", "XAD"].includes(ATD);

export const isMDLMobile = (applicationType) => applicationType === "mdl";

export const autoSubmitOptIn = {
  OPTED_IN: "optedIn",
  OPTED_OUT: "optedOut",
};

export const aidCaptureError = {
  errorCode1: "Face not detected.",
  errorCode2: "Hold still when uploading document to avoid blur.",
  errorCode3: "Avoid glare on the document.",
  errorCode4: "Barcode not detected.",
  errorCode99: "We encountered issues with your images. Please try again.",
};