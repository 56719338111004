import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { RadioGroup, RadioItem } from "@ca-dmv/core";
import { getOptedCategory, postOptedCategory } from "@ca-dmv-radv/data";

export default function OptionalDocumentPrompt({ applicationType, proofOfDocumentCategory, setWillUpload, optedIn }) {
  const { t } = useTranslation();

  return (
    <RadioGroup
      legend={t(
        `${applicationType}-category${proofOfDocumentCategory.categoryId}-optional-prompt`, 
        `Would you like to upload ${proofOfDocumentCategory.categoryName} documents?`
      )}
      required={false}
      containerClass="mb-8"
      legendClass="text--sm text--h5"
    >
      <RadioItem
					containerClass="mb-8"
					label={t(
            `${applicationType}-category${proofOfDocumentCategory.categoryId}-optionalUpload-yes`, 
            "Yes"
          )}
					onChange={async () => {
            await postOptedCategory(applicationType, proofOfDocumentCategory.categoryId, true);
            setWillUpload(true);
					}}
					required={false}
          checked={optedIn === true}
				/>
        <RadioItem
					containerClass="mb-8"
					label={t(
            `${applicationType}-category${proofOfDocumentCategory.categoryId}-optionalUpload-no`, 
            "No"
          )}
					onChange={async () => {
            await postOptedCategory(applicationType, proofOfDocumentCategory.categoryId, false);
            setWillUpload(false);
					}}
					required={false}
          checked={optedIn === false}
          confirmation={t(
            `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-optionalNoMessage`,
            `You have chosen to opt-out of uploading ${proofOfDocumentCategory.categoryName}. If you would like to opt-in to uploading ${proofOfDocumentCategory.categoryName}, select 'Yes' to update your selection.`
          )}
          confirmationIcon="check"
				/>
    </RadioGroup>
  );
}