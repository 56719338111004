import React, { useState, useEffect } from "react";
import { formatName, numberOfDocuments, formatDate, formatTime} from "@ca-dmv-radv/utilities";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import {
  Icon,
  ButtonWrapper,
  Button,
  BUTTON_STYLE_LINK,
  ICON_CHECK,
  ICON_ENVELOPE,
  Alert,
  ALERT_INFO,
  ALERT_ERROR
} from "@ca-dmv/core";
import {
  useApplication,
  useDocumentUploads,
  useAppointments,
  APPLICATION_TYPE,
  getPaymentDetails,
  postPayment,
} from "@ca-dmv-radv/data";
import EmailDocument from "../dashboard/EmailDocument";
import Barcodes from "../dashboard/Barcodes";
import CustomerInfo from "./CustomerInfo";
import useTranslateDocumentName from "../dashboard/helpers/useTranslateDocumentName";
import { useNavigate, useLocation } from "react-router-dom";
import SelectALocationButton from "../dashboard/SelectALocationButton";
import { isApplicationNch, nchCategoryNameMap, nchStatusSubtitles, NCH_STATUS_ERROR, NCH_STATUS_PROCESSING, categoryIds, NCH_STATUS_PENDING } from "../constants";
import { ICON_WARNING } from "@ca-dmv/core/dist/js/icon";

function getCategoryOrder(categoryAndDocs) {
    let categoryOrder = new Array();
    categoryAndDocs.forEach((element) => {
      categoryOrder.push(element.categoryId);
    });
    return categoryOrder;
}

function DocumentList({ documents, applicationType, categoryAndDocs }) {
  const isNch = isApplicationNch(applicationType);
  // Use order of docs on dashboard to display the docs on the final confirmation page
  const categoryOrder = isNch ? [9, 11, 12, 10] : getCategoryOrder(categoryAndDocs);
  const docArray = (Object.keys(documents).map((key) => {
    return documents[key].map((document) => document);
  }));
  docArray.sort((a, b) => categoryOrder.indexOf(a[0].categoryId) - categoryOrder.indexOf(b[0].categoryId));
  return (
    <>
      {docArray.map((document) => {
          const docDetails = document[0];
          const translatedDocumentName = useTranslateDocumentName(docDetails);
          return (
            <div key={docDetails.id}>
              {isNch && docDetails.categoryId !== categoryIds.NCH_NAME_TRACING && (
                <p className="text--700 text--blue-dark-2 text--md mb-24 flex flex--align-top flex--nowrap text--lh-1">
                  <Trans
                    i18nKey={nchCategoryNameMap[docDetails.category]}
                    defaults={docDetails.category}
                  />
                </p>
              )}
              {isNch && docDetails.categoryId === categoryIds.NCH_NAME_TRACING && (
                <p className="text--700 text--blue-dark-2 text--sm mb-24 pl-24 flex flex--align-top flex--nowrap text--lh-1">
                  <Trans
                    i18nKey={nchCategoryNameMap[docDetails.category]}
                    defaults={docDetails.category}
                  />
                </p>
              )}
              {docDetails.categoryId !== categoryIds.NCH_NAME_TRACING ? (
                <p className="text--700 text--blue-dark-3 text--sm mb-24 flex flex--align-top flex--nowrap text--lh-1">
                  <Icon icon={ICON_CHECK} className="min-width--30 mt-2" />
                  <span>{translatedDocumentName || docDetails.name}</span>
                </p>
              ) : (
                <p className="text--700 text--blue-dark-2 text--sm mb-24 pl-24 flex flex--align-top flex--nowrap text--lh-1">
                  <Icon icon={ICON_CHECK} className="min-width--30 mt-2" />
                  <span>{translatedDocumentName || docDetails.name}</span>
                </p>
              )}
            </div>
          );
        })
      }
    </>
  );
}

function PaymentInfo({ feeAmount, paymentDetails }) {
  const { t } = useTranslation();
  // Formatting for payment detail values
  const formattedSeqNum = ("0000" + paymentDetails.sequenceNumber).slice(-4);
  const formattedConfirmation = paymentDetails.officeId + paymentDetails.workDate + paymentDetails.techId + formattedSeqNum;
  const formattedFeeAmount = new Intl.NumberFormat("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(feeAmount);

  return (
  <div className="flex flex--col">
    {(
      <div className="w--100 max-width--500 p-20 bp-md:p-30 bg--blue-light-2 mb-24">
        <p className="text--sm mb-0 text--blue-dark-2 text--700">
          {t(
            "screens-FinalConfirmation-nameChange-paymentHeader",
            "Payment Details"
          )}
        </p>
        <p className="text--sm mb-0 text--blue-dark-2">
          <Trans
              i18nKey="screens-FinalConfirmation-nameChange-paymentItem-1"
              defaults="Payment Amount: ${{paymentAmount}}"
              values={{
                paymentAmount: formattedFeeAmount
              }}
          />
        </p>
        <p className="text--sm mb-0 text--blue-dark-2">
          <Trans
              i18nKey="screens-FinalConfirmation-nameChange-paymentItem-2"
              defaults="Payment Confirmation: {{paymentConfirmationCode}}"
              values={{
                paymentConfirmationCode: formattedConfirmation,
              }}
          />
        </p>
        <p className="text--sm mb-0 text--blue-dark-2">
          <Trans
              i18nKey="screens-FinalConfirmation-nameChange-paymentItem-3"
              defaults="Payment Received Date: {{paymentReceivedDate}}"
              values={{
                paymentReceivedDate: paymentDetails?.workDate,
              }}
          />
        </p>
      </div>
    )}
   </div>
  )}

function AppointmentDetails({ selectedAppointment, selectedFieldOffice, lang }) {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text--primary text--md">
        {t("screens-appointment-details-label", "Appointment Details")}
      </h3>
      <div className="pr-24 pb-24" style={{ display: "flex" }}>
        <div className="pr-24 mr-36">
          {selectedFieldOffice && (
            <div className="mb-0">
              <p className="text--blue-dark-1 text--sm bp-sm:text--md text--700 mb-0">
                {selectedFieldOffice?.title?.rendered}
              </p>
              <p className="text--blue-dark-1 text--sm bp-sm:text--md text--700 mb-0">
                {selectedFieldOffice?.meta.dmv_field_office_street}
              </p>
              <p className="text--blue-dark-1 text--sm bp-sm:text--md text--700 mb-0">
                {`${selectedFieldOffice?.meta.dmv_field_office_city}, CA ${selectedFieldOffice?.meta.dmv_field_office_zipcode}`}
              </p>
            </div>
          )}
        </div>
        <div className="pl-24">
          {selectedAppointment && (
            <div>
              <div className="mb-0">
                <p className="text--blue-dark-1 text--sm bp-sm:text--md text--700 mb-0">
                  {formatDate(selectedAppointment?.date, lang)}{" "}
                </p>
                <p className="text--blue-dark-1 text--sm bp-sm:text--md text--700 mb-0">
                  {formatTime(selectedAppointment?.time)}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <h3 className="text--primary text--red text--md">
        {t("email-finalConfirmation-code-label", "Keep this confirmation code for your DMV appointment")}
      </h3>
    </div>
  );
}

function StatusDisplay({ status }) {
  const { t } = useTranslation();
  const statusText = t(`screens-FinalConfirmation-status-${status}`, status);
  return (
    <div className="mb-20 mt-20" style={{ display: "flex" }}>
      <p className="text--700 text--primary text--md text--black pr-12 flex flex--align-center flex--nowrap">
        <span style={{ "font-weight": "bold" }}>
          <Trans
            i18nKey=""
            defaults="Status:"
          />
        </span>
      </p>
      <p className="text--700 text--black p-12 text--md flex flex--align-center flex--nowrap bg--gray-light-2">
        {statusText}
        <Icon
          icon={status === "error" ? ICON_WARNING : null}
          className="min-width--30 align-center"
        />
      </p>
    </div>
  );
}

function EmailConfirmation({ email, showIcon = true }) {
  return (
    <div className="text--blue-dark-2 mt-16 flex flex--align-start bp-lg:flex--align-center flex--nowrap">
      {showIcon && <Icon
        icon={ICON_ENVELOPE}
        className="mr-8 mt-5 bp-lg:mt-0 min-width--30"
      />}

      <p className="mb-0">
        <Trans
          i18nKey="screens-FinalConfirmation-emailCopy"
          defaults="For your convenience, an email with a copy of this page has been sent to <span>{{email}}</span>."
          values={{ email }}
          components={{ span: <span className="text--700" /> }}
        />
      </p>
    </div>
  )
}

export default function FinalConfirmation({ title, applicationType }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const { application } = useApplication();
  const {
    selectedFieldOffice,
    selectedAppointment,
    notificationMethod,
    telNumber,
    needsAccommodations,
  } = useAppointments();
  const [addEmail, setAddEmail] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { documentUploads } = useDocumentUploads();
  const filteredDocs = documentUploads.hasOwnProperty(6) && delete documentUploads[6];
  const isNch = isApplicationNch(applicationType);
  // TODO: May need to change botStatus value depending on status key
  const [applicationStatus, setApplicationStatus] = useState(null);
  const isPhotoFlow = isNch ? !application.skipDMV : false;
  const showAppointmentScheduler = isPhotoFlow && !selectedAppointment && applicationStatus != NCH_STATUS_ERROR;

  const {
    email,
    confirmationCode,
    application: { name },
  } = application;
  const legalName = formatName(name);
  const navigate = useNavigate();
  const location = useLocation();
  const showBarcodes = !isNch || selectedAppointment || [NCH_STATUS_ERROR, NCH_STATUS_PROCESSING, NCH_STATUS_PENDING].includes(applicationStatus) || !application?.skipDMV;
  const categoryAndDocs = location.state.categoryAndDocs;

  useEffect(async () => {
    await getPaymentDetails(applicationType).then((response) => {
      if (response.success && response.data?.sequenceNumber) {
        setPaymentDetails(response.data);
      }
    });
  }, []);

  useEffect(() => {
    setApplicationStatus(application.status?.BOT ? application.status.BOT : "paymentNeeded");
  }, []);

  const confirmationCodeLabel = () => {
    if (!isNch) {
      return t(
        "screens-FinalConfirmation-confirmationCode",
        "This is your confirmation code:"
      );
    } else {
      return t(
        "screens-FinalConfirmation-nch-confirmationCodeLabel",
        "Confirmation Code:"
      );
    }
  };

  const initiatePaymentWorkflow = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const { success, data } = await postPayment(applicationType);

      if (!success)
        throw new Error();

      window.location = data;
    } catch {
      setIsLoading(false);
      setHasError(true);
    }
  }

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="max-width--800">
        {applicationStatus != NCH_STATUS_ERROR && <h2 className="text--md bp-md:text--lg text--700 mb-24 bp-md:mb-48">
          {t("screens-FinalConfirmation-heading", "Good News!")}
        </h2>}
        {showBarcodes && <Barcodes barcodeType={39} />}
        <h3 className="text--primary text--md text--black text--700 mb-24">
          {isNch ?
            ((isPhotoFlow && applicationStatus != NCH_STATUS_ERROR) ? t(
              "screens-FinalConfirmation-nameChange-photoFlow-subtitle",
              "Your documents have been accepted and pre-approved by our online verification system."
            ) :
            <Trans 
              i18nKey={`screens-FinalConfirmation-nameChange-${applicationStatus}-subtitle`}
              defaults={nchStatusSubtitles[applicationStatus]}
              values={{
                email: application?.email,
              }}
            />) :
            t(
              "screens-FinalConfirmation-subtitle",
              "Your REAL ID documents have been pre-approved by our online verification system."
            )
          }
        </h3>

        {(isNch && !isPhotoFlow) && <StatusDisplay status={applicationStatus}/>}

        {isNch && !paymentDetails?.workDate && (
          <div className="w--100 p-20 bp-md:p-30 bg--gray-light-2 mb-24">
            <p className="text--md mb-0 text--blue-dark-2 text--700 mb-16">
              {t("screens-FinalConfirmation-payment-heading", "Make your payment to finalize your application")}
            </p>
            {hasError && (
              <Alert 
                alertStyle={ALERT_ERROR}
                content={t("screens-FinalConfirmation-payment-error", "An unknown error occurred. Please try again.")}
              />
            )}
            <Button 
              label={t("screens-FinalConfirmation-payment-buttonText", "Pay Now")} 
              onClick={async () => {
                await initiatePaymentWorkflow();
              }}
              isLoading={isLoading}
            />
          </div>
        )}

        {!isNch && selectedAppointment && (
          <p className="text--blue-dark-2 text--sm mb-24">
            {t(
              "screens-FinalConfirmation-appointmentDetails",
              "Your appointment details have been successfully submitted to DMV. If you need to cancel or reschedule your appointment, use the online "
            )}
            <a href={process.env.REACT_APP_ALL_APPOINTMENTS_LINK}>
              {t(
                "screens-FinalConfirmation-appointmentsPage",
                "Appointment Center."
              )}
            </a>
          </p>
        )}

        {(isPhotoFlow && applicationStatus != NCH_STATUS_ERROR && email) && <div className="mb-24">
          <EmailConfirmation email={email} showIcon={false} />
        </div>}

        {notificationMethod && telNumber && (
          <CustomerInfo
            legalName={legalName}
            notificationMethod={notificationMethod}
            telNumber={telNumber}
          />
        )}
        {selectedAppointment && selectedFieldOffice && ( 
          <AppointmentDetails 
            selectedAppointment={selectedAppointment}
            selectedFieldOffice={selectedFieldOffice}
            lang={application?.lang}
          />
        )}

        <div className="flex flex--col">
          {confirmationCode && (
            <div className="w--100 max-width--500 p-20 bp-md:p-30 bg--yellow-light-2 mb-24">
                <p className="text--sm mb-0 text--blue-dark-2 text--700">
                  {confirmationCodeLabel()}
                </p>
              <p className="text--md text--700 text--blue-dark-1 mb-0">
                {confirmationCode}
              </p>
            </div>
          )}
        </div>

        {paymentDetails?.workDate && (
          <PaymentInfo
            feeAmount={application?.feeAmount}
            paymentDetails={paymentDetails}
          />
        )}

        {showAppointmentScheduler &&
          <div className="mt-20 mb-md:mt-48 max-width--700">
            <p className="text--blue-dark-2">
              <Trans
                i18nKey="screens-confirmation-nameChange-makeAppointment"
                defaults="Click <span>Select a Location</span> below to make an appointment at a DMV location to finalize your Name Change. Please bring the documents you have submitted with you to your appointment."
                components={{ span: <span className="text--700" /> }}
              />
            </p>
            <SelectALocationButton />
          </div>
        }

        {/* {selectedAppointment && (
          <div className="w--100 p-20 bp-md:p-30 bg--gray-light-2 mb-24">
            <p className="text--md text--blue-dark-2 text--700">
              {t(
                "screens-FinalConfirmation-saveTime-heading",
                "Save time at your upcoming DMV appointment"
              )}
            </p>
            <p className="text--blue-dark-2 text--sm mb-0">
              {t(
                "screens-FinalConfirmation-saveTime-subheading",
                "You can now save time by"
              )}
            </p>
            <ul className="mt-0">
              <li className="text--blue-dark-2 text--sm">
                {t(
                  "screens-FinalConfirmation-saveTime-item1",
                  "Confirming your summary information"
                )}
              </li>
              <li className="text--blue-dark-2 text--sm">
                {t(
                  "screens-FinalConfirmation-saveTime-item2",
                  "Making a payment"
                )}
              </li>
            </ul>
            <ButtonWrapper wrapperClass="mt-40 bp-md:mt-40">
              <Button 
                label={t("screens-FinalConfirmation-saveTime-confirmPayLabel", "Confirm and Pay")} 
                onClick={() => {
                  navigate("/attest");
                }} 
              />
            </ButtonWrapper>
          </div>
        )} */}
        {showAppointmentScheduler &&
          <div className="border--top mt-24 pt-24 mb-24">
            <h4 className="text--primary text--md text--blue-dark-2 text--700 mb-0">
              {!isNch &&
                <Trans
                  i18nKey="screens-FinalConfirmation-bringDocuments"
                  defaults="Bring these {{numDocs}} documents with you to your DMV appointment to finalize your REAL ID."
                  values={{
                    numDocs: numberOfDocuments(documentUploads),
                  }}
                />
              }
            </h4>
          </div>}

        <DocumentList documents={documentUploads} applicationType={applicationType} categoryAndDocs={categoryAndDocs}/>
        {!isNch &&
          <div>
            <p className="text--blue-dark-2">
              {t(
                "screens-FinalConfirmation-printDocuments",
                "Print this page and bring it to your DMV appointment for an express experience."
              )}
            </p>
            <p className="text--blue-dark-2 mb-0">
              <Trans
                i18nKey="screens-FinalConfirmation-startHereWindow"
                defaults="When you arrive, report to the <span>Start Here</span> window."
                components={{ span: <span className="text--700" /> }}
              />
            </p>
          </div>
        }

        {!isNch && <div className="mb-24 mt-40 ">
          <EmailConfirmation email={email} />
        </div>}

      </div>
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        {/* <Button
          buttonClass="mb-0 bp-md:mr-24"
          label={t("modal-Share-title", "Share a copy of this page")}
          btnStyle={BUTTON_STYLE_LINK}
          onClick={() => {
            setAddEmail(true);
          }}
        /> */}
        <Button
          buttonClass="mb-24 bp-md:mb-0"
          label={t("shared.donelabel", "Done")}
          onClick={() => {
            window.location = process.env.REACT_APP_THANK_YOU_LINK;
          }}
        />
      </ButtonWrapper>

      {addEmail && (
        <EmailDocument
          type="final-confirmation"
          errorAlert={t(
            "errorMessages.addingEmail",
            "There was an error adding your email address. Please try again."
          )}
          onClose={() => {
            setAddEmail(false);
          }}
          actionButtonText={t("shared.continueLabel", "Continue")}
        />
      )}
    </RadvPageWrapper>
  );
}
