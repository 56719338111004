import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useMounted } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  useThrowFetchError,
  useThrowErrorSavingData,
} from "./error-context-provider";
import {
  getApplicationData,
  submitToFODI,
  invalidateSession,
  sendEmailSessionSave,
  updateAutoSubmit,
} from "./api";
import { DEFAULT_MAX_UPLOAD_SIZE, APPLICATION_TYPE } from "./constants";

const ApplicationContext = React.createContext();

export function ApplicationContextProvider({
  children,
  application: initialApplication,
  applicationType,
}) {
  const [application, setApplication] = useState(initialApplication);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validationComplete, setValidationComplete] = useState(false);
  const [isMDLDocument] = useState(applicationType === APPLICATION_TYPE.MDL);

  const { t } = useTranslation();
  const mounted = useMounted();
  const throwFetchError = useThrowFetchError();
  const throwErrorSavingData = useThrowErrorSavingData();
  const navigate = useNavigate();

  /**
   * Fetches the the entire application object from the backend.
   */
  const fetchApplication = useCallback(async () => {
    let data;
    let success;

    try {
      ({ success, ...data } = await getApplicationData(applicationType));
      if (!success) {
        throw new Error("getApplicationData");
      }

      if (mounted.current) {
        setApplication(data);
      }
    } catch (error) {
      throwFetchError({
        error,
        message: t(
          "app-error-fetchingData-application",
          "There was an error fetching your application data."
        ),
      });
    }

    return data;
  }, [throwFetchError, mounted, t]);

  /**
   * Fires when save and exit application is triggers by button or timeout and on log out button
   */
  const saveAndExitApplication = useCallback(async (isLogout) => {
    try {
      if (mounted.current) {
        const email = application?.email;
        if (email) {
          await sendEmailSessionSave(email);
        }

        await invalidateSession();
      }
    } finally {
      if (isLogout) {
        navigate("/log-out");
      } else {
        navigate("/saved");
      }
    }
  }, [throwFetchError, mounted, application]);

  /**
   * Submit application once all documents are uploaded and approved.
   */
  const submitApplication = useCallback(async () => {
    let success;
    let message;

    try {
      ({ success, message } = await submitToFODI());

      if (!success) {
        throw new Error(`submitToFODI: ${message}`);
      }
    } catch (error) {
      throwErrorSavingData({
        error,
        message: t(
          "app-error-savingData-application",
          "There was an error submitting your application. Try again."
        ),
      });
    }

    return success;
  }, [throwErrorSavingData, mounted]);

  const submitAutoSubmit = useCallback(async (optIn) => {
    let success;

    try {
      ({ success } = await updateAutoSubmit(optIn));

      if (!success) {
        throw new Error("ServerError");
      }
    } catch (error) {
      throwErrorSavingData({
        error,
        message: t(
          "app-error-savingData-autoSubmit",
          "There was an error saving the result of your opt in decision. Please refresh the page and try again."
        ),
      });

      return false;
    }

    return true;
  });

  return (
    <ApplicationContext.Provider
      value={useMemo(
        () => ({
          application,
          applicationName: `${application?.application?.name.firstName || ""} ${
            application?.application?.name.middleName || ""
          } ${application?.application?.name.lastName || ""} ${
            application?.application?.name.suffix || ""
          }`
            .replace(/\s+/g, " ")
            .trim(),
          fetchApplication,
          maxFileSize: application?.maxFileSize || DEFAULT_MAX_UPLOAD_SIZE,
          setApplication,
          saveAndExit,
          setSaveAndExit,
          saveAndExitApplication,
          submitting,
          setSubmitting,
          submitApplication,
          validationComplete,
          setValidationComplete,
          isMDLDocument,
          applicationType,
          submitAutoSubmit,
        }),
        [
          application,
          fetchApplication,
          saveAndExit,
          saveAndExitApplication,
          submitting,
          validationComplete,
          isMDLDocument,
          applicationType,
        ]
      )}
    >
      {children}
    </ApplicationContext.Provider>
  );
}

export function useApplication() {
  return useContext(ApplicationContext);
}
