import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { Alert, ALERT_SUCCESS } from "@ca-dmv/core";
import { useApplication } from "@ca-dmv-radv/data";
import { isApplicationNch } from "../constants";

export default function ReadyToSubmit() {
  const { t } = useTranslation();
  const { applicationType } = useApplication();

  return (
    <Alert
      alertStyle={ALERT_SUCCESS}
      containerClass="mt-48 mb-0 max-width--600"
      hasBorder={false}
      role="alert"
    >
      <h4 className="text--primary text--700 mb-8">
        {t(
          "screens-Dashboard-readyToSubmit",
          "Your documents have been verified and are ready to submit."
        )}
      </h4>
      <p className="text--blue-dark-2">
        {isApplicationNch(applicationType)
          ? t(
              "nch-screens-Dashboard-readyToSubmit-explanation-1",
              "Submit your documents and make payment to finalize your application."
            )
          : t(
              "screens-Dashboard-readyToSubmit-explanation-1",
              "A visit to the DMV is the final step in your REAL ID application."
            )}
      </p>
    </Alert>
  );
}
